import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import image404 from './assets/images/404.png'

const NotFoundPage = () => {
  const pageContext = {
    metaTitle: '404 - Página não encontrada',
    metaDescription: 'A página não foi encontrada',
  }

  return (
    <Layout pageContext={pageContext}>
      <section className='pt-4 py-lg-5 text-center text-lg-left'>
        <div className='container pt-xl-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='fs-30 fs-lg-42 text-grayscale--500 mb-md-5'>Ops... página não encontrada!</h3>
            </div>
            <div className='col-sm-12 col-lg-5 col-xl-6 order-lg-2 pb-4 pb-lg-0 text-center mt-lg-n4 mt-xl-n5'>
              <img className='img-fluid' src={image404} alt='404 image' />
            </div>
            <div className='col-sm-12 col-lg-7 col-xl-6 order-lg-1 pb-5'>
              <p className='fs-18 lh-22 fs-lg-20 text-grayscale--500'>Aqui estão alguns links que você pode estar procurando:</p>
              <ul className='list-unstyled link-list--404 fw-400'>
                <li>
                  <Link to='/' className='text-grayscale--500' title='Home'>Home</Link>
                </li>
                <li>
                  <Link to='/#beneficios' className='text-grayscale--500' title='Home'>Benefícios</Link>
                </li>
                <li>
                  <Link to='/#depoimentos' className='text-grayscale--500' title='Home'>Depoimentos</Link>
                </li>
                <li>
                  <Link to='/#area-de-atuacao' className='text-grayscale--500' title='Home'>Área de Atuação</Link>
                </li>
                <li>
                  <Link to='/carreiras' className='text-grayscale--500' title='Home'>Vagas</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
